<template>
  <AppCard sheet>
    <AppCardImageTitle
      :src="require('@galileo/assets/images/illustrations/cross-mark.svg')"
      :title="$t('PageSendMoneyFailed.TitleCard').value"
      alt="Warning"
    >
      <div class="description">
        {{ $t('PageSendMoneyFailed.DescriptionCard').value }}
      </div>
    </AppCardImageTitle>

    <ButtonGroup>
      <AppButton analytics-name="send-failed-change-payment-method" @click="$emit('goToPayments')">
        {{ $t('PageSendMoneyFailed.ButtonChangePaymentMethod').value }}
      </AppButton>
      <AppButton
        analytics-name="send-failed-cancel-transaction"
        theme="secondary"
        @click="$emit('cancelTransaction')"
      >
        {{ $t('PageSendMoneyFailed.ButtonCancelTransaction').value }}
      </AppButton>
    </ButtonGroup>
  </AppCard>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { AppButton, AppCardImageTitle, AppCard } from '@oen.web.vue2/ui'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'

export default {
  name: 'QuoteExpiredCard',
  components: {
    AppButton,
    AppCardImageTitle,
    AppCard,
    ButtonGroup,
  },
  emits: ['goToPayments', 'cancelTransaction'],
  setup() {
    const { $t } = useI18nStore()

    return {
      $t,
    }
  },
}
</script>
