<template>
  <AppCard sheet>
    <AppCardImageTitle
        v-if="userCountry === 'US'"
        :src="require('@galileo/assets/images/illustrations/cross-mark.svg')"
        :title="$t('PageSendMoneyFailed.TitleACH').value"
        alt="Warning"
      >
        <div class="description">
          {{ $t('PageSendMoneyFailed.DescriptionACH').value }}
        </div>
      </AppCardImageTitle>

      <AppCardImageTitle
        v-else-if="userCountry === 'CA'"
        :src="require('@galileo/assets/images/illustrations/cross-mark.svg')"
        :title="$t('PageSendMoneyFailed.TitleEFT').value"
        alt="Warning"
      >
        <div class="description">
          {{ $t('PageSendMoneyFailed.DescriptionEFT').value }}
        </div>
      </AppCardImageTitle>
    <ButtonGroup>
      <AppButton analytics-name="send-failed-change-payment-method" @click="$emit('goToPayments')">
        {{ $t('PageSendMoneyFailed.ButtonChangePaymentMethod').value }}
      </AppButton>
      <AppButton
        analytics-name="send-failed-cancel-transaction"
        theme="secondary"
        @click="$emit('cancelTransaction')"
      >
        {{ $t('PageSendMoneyFailed.ButtonCancelTransaction').value }}
      </AppButton>
    </ButtonGroup>
  </AppCard>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { AppButton, AppCardImageTitle, AppCard } from '@oen.web.vue2/ui'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'

export default {
  name: 'QuoteExpiredCard',
  components: {
    AppButton,
    AppCardImageTitle,
    AppCard,
    ButtonGroup,
  },
  emits: ['goToPayments', 'cancelTransaction'],
  props: {
    isCustomerCardSpecificError: {
      type: Boolean,
      required: false,
      default: false,
    },
    userCountry: { 
        type: String, 
        requried: true 
    },
  },
  setup() {
    const { $t } = useI18nStore()

    return {
      $t,
    }
  },
}
</script>
