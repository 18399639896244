<template>
    <AppCard sheet>
        <AppCardImageTitle
        :src="require('@galileo/assets/images/illustrations/cross-mark.svg')"
        :title="$t('PageSendMoneyFailed.TitleSinglePaymentMethod').value"
        alt="Warning"
      >
        <div class="description">
          {{ $t('PageSendMoneyFailed.DescriptionSinglePaymentMethod').value }}
        </div>
      </AppCardImageTitle>
      <ButtonGroup>
        <AppButton
          analytics-name="send-failed-update-payment-method"
          @click="$emit('goToPayments')"
        >
          {{ $t('PageSendMoneyFailed.ButtonUpdatePaymentMethod').value }}
        </AppButton>
        <AppButton
          theme="secondary"
          analytics-name="send-failed-visit-help-centre"
          @click="$emit('goToHelpCentre')"
        >
          {{ $t('PageSendMoneyFailed.VisitHelpCentre').value }}
        </AppButton>
      </ButtonGroup>
    </AppCard>
  </template>
  
  <script>
  import { useI18nStore } from '@galileo/stores'
  import { AppButton, AppCardImageTitle, AppCard } from '@oen.web.vue2/ui'
  import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
  import { type } from 'os';
  
  export default {
    name: 'QuoteExpiredCard',
    components: {
      AppButton,
      AppCardImageTitle,
      AppCard,
      ButtonGroup,
    },
    emits: ['goToPayments', 'cancelTransaction', 'goToHelpCentre'],
    props: {
      isCustomerCardSpecificError: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup() {
      const { $t } = useI18nStore()
  
      return {
        $t,
      }
    },
  }
  </script>
  