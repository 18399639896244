<template>
  <AppCard sheet>
    <template v-if="isMultiplePaymentMethodsAvailable">
      <AppCardImageTitle
        v-if="
          selectedPaymentMethod === PAYMENT_METHODS.DEBIT_CARD ||
          selectedPaymentMethod === PAYMENT_METHODS.CREDIT_CARD
        "
        :src="require('@galileo/assets/images/illustrations/cross-mark.svg')"
        :title="$t('PageSendMoneyFailed.TitleCard').value"
        alt="Warning"
      >
        <div class="description">
          {{ $t('PageSendMoneyFailed.DescriptionCard').value }}
        </div>
      </AppCardImageTitle>

      <AppCardImageTitle
        v-else-if="selectedPaymentMethod === PAYMENT_METHODS.DIRECT_DEBIT && userCountry === 'US'"
        :src="require('@galileo/assets/images/illustrations/cross-mark.svg')"
        :title="$t('PageSendMoneyFailed.TitleACH').value"
        alt="Warning"
      >
        <div class="description">
          {{ $t('PageSendMoneyFailed.DescriptionACH').value }}
        </div>
      </AppCardImageTitle>

      <AppCardImageTitle
        v-else-if="
          selectedPaymentMethod === PAYMENT_METHODS.DIRECT_DEBIT &&
          userCountry === 'CA' &&
          !accountIsRestricted
        "
        :src="require('@galileo/assets/images/illustrations/cross-mark.svg')"
        :title="$t('PageSendMoneyFailed.TitleEFT').value"
        alt="Warning"
      >
        <div class="description">
          {{ $t('PageSendMoneyFailed.DescriptionEFT').value }}
        </div>
      </AppCardImageTitle>

      <AppCardImageTitle
        v-else
        :src="require('@galileo/assets/images/illustrations/cross-mark.svg')"
        :title="$t('PageSendMoneyFailed.TitleSinglePaymentMethod').value"
        alt="Warning"
      >
        <div class="description">
          {{ $t('PageSendMoneyFailed.DescriptionSinglePaymentMethod').value }}
        </div>
      </AppCardImageTitle>

      <ButtonGroup>
        <AppButton
          analytics-name="send-failed-change-payment-method"
          @click="$emit('goToPayments')"
        >
          {{ $t('PageSendMoneyFailed.ButtonChangePaymentMethod').value }}
        </AppButton>
        <AppButton
          analytics-name="send-failed-cancel-transaction"
          theme="secondary"
          @click="$emit('cancelTransaction')"
        >
          {{ $t('PageSendMoneyFailed.ButtonCancelTransaction').value }}
        </AppButton>
      </ButtonGroup>
    </template>
    <template v-else>
      <AppCardImageTitle
        :src="require('@galileo/assets/images/illustrations/cross-mark.svg')"
        :title="$t('PageSendMoneyFailed.TitleSinglePaymentMethod').value"
        alt="Warning"
      >
        <div class="description">
          {{ $t('PageSendMoneyFailed.DescriptionSinglePaymentMethod').value }}
        </div>
      </AppCardImageTitle>
      <ButtonGroup>
        <AppButton
          analytics-name="send-failed-update-payment-method"
          @click="$emit('goToPayments')"
        >
          {{ $t('PageSendMoneyFailed.ButtonUpdatePaymentMethod').value }}
        </AppButton>
        <AppButton
          theme="secondary"
          analytics-name="send-failed-visit-help-centre"
          @click="$emit('goToHelpCentre')"
        >
          {{ $t('PageSendMoneyFailed.VisitHelpCentre').value }}
        </AppButton>
      </ButtonGroup>
    </template>
  </AppCard>
</template>

<script>
import { AppButton, AppCardImageTitle, AppCard } from '@oen.web.vue2/ui'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
import { useI18nStore } from '@galileo/stores'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'

export default {
  name: 'PaymentMethodErrorCard',
  components: {
    ButtonGroup,
    AppCardImageTitle,
    AppButton,
    AppCard,
  },
  emits: ['cancelTransaction', 'goToPayments', 'goToHelpCentre'],
  props: {
    isMultiplePaymentMethodsAvailable: { type: Boolean, required: true },
    selectedPaymentMethod: { type: String, requried: true },
    userCountry: { type: String, requried: true },
    accountIsRestricted: { type: Boolean, requried: true },
  },
  setup(props) {
    const { $t } = useI18nStore()

    return { $t }
  },
}
</script>
