<template>
  <div>
    <AccountRestricted v-if="accountIsRestricted" />

    <QuickTransferFailedCard
      v-if="isOneStepTransferError"
      @tryAgain="goToSummary"
      @goToHelpCentre="goToHelpCentre"
    />

    <GenericRiaErrorCard
      v-if="isGenericRiaError"
      @goToActivity="goToActivity"
      @goToHelpCentre="goToHelpCentre"
    />

    <UnexpectedErrorCard
      v-if="isUnexpectedError"
      @goToHelpCentre="goToHelpCentre"
      @goBack="goToSummary"
    />

    <!-- Open Banking specific payment errors-->
    <template v-if="isOpenBanking">
      <TimeoutIssueCard
        v-if="isOpenBankingQuoteExpireError"
        @goToActivity="goToActivity"
        @howLongRefunds="howLongRefunds"
      />

      <OpenBankingPaymentFailedCard
        v-if="isOpenBankingPaymentError"
        @goToPaymentsVolt="goToPaymentsVolt"
        @goToSummary="goToSummary"
      />
    </template>

    <!-- Card specific payment errors-->
    <template v-if="isCardError">
      <CardConsumerSpecificError
        v-if="isCustomerCardSpecificError"
        @goToPayments="goToPayments"
        @cancelTransaction="cancelTransaction"
      />

      <QuoteExpiredCard
        v-else-if="isQuoteExpired"
        @cancelTransaction="cancelTransaction"
        @goToSummary="goToSummary"
      />

      <CreditDebitErrorCard
        v-else
        @goToPayments="goToPayments"
        @cancelTransaction="cancelTransaction"
      />
    </template>

    <!-- Direct Debit specific payment error-->
    <DirectDebitErrorCard
      v-if="isDeirectDebitError"
      :user-country="userCountry"
      @goToPayments="goToPayments"
      @cancelTransaction="cancelTransaction"
    />

    <SinglePaymentMethodError
      v-if="isPaymentMethodError && !isMultiplePaymentMethodsAvailable && !isQuoteExpired"
      @goToPayments="goToPayments"
      @cancelTransaction="cancelTransaction"
      @goToHelpCentre="goToHelpCentre"
    />
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'

import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
import { AppButton, AppCardImageTitle, AppCard } from '@oen.web.vue2/ui'
import AccountRestricted from '@galileo/components/Views/SendMoney/Amount/AccountRestricted'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import {
  QuickTransferFailedCard,
  OpenBankingPaymentFailedCard,
  GenericRiaErrorCard,
  PaymentMethodErrorCard,
  TimeoutIssueCard,
  UnexpectedErrorCard,
  QuoteExpiredCard,
  CreditDebitErrorCard,
  SinglePaymentMethodError,
  CardConsumerSpecificError,
} from './sendMoneyFailedCards'

import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

import { PAYMENT_METHODS, TRANSFER_TYPE } from '@galileo/constants/sendMoneyFlow.const.js'

import {
  useI18nStore,
  useAuthStore,
  useAnalyticsStore,
  useSendMoneyStore,
  useAppStore,
  useQuickTransferStore,
  useCardAccountStore,
  usePaymentsStore,
} from '@galileo/stores'

export default {
  name: 'SendMoneyFailed',
  components: {
    ButtonGroup,
    AppCardImageTitle,
    AppButton,
    AppCard,
    AccountRestricted,
    QuickTransferFailedCard,
    TimeoutIssueCard,
    OpenBankingPaymentFailedCard,
    GenericRiaErrorCard,
    PaymentMethodErrorCard,
    UnexpectedErrorCard,
    QuoteExpiredCard,
    CreditDebitErrorCard,
    SinglePaymentMethodError,
    CardConsumerSpecificError,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const sendMoneyStore = useSendMoneyStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const cardAccount = useCardAccountStore()
    const paymentsStore = usePaymentsStore()
    const quickTransferStore = useQuickTransferStore()
    const paymentMethod = paymentsStore.getSelectedPaymentMethod()
    const isQuoteExpired = sendMoneyStore.quoteExpired
    const isOpenBanking = computed(
      () => sendMoneyStore.form.paymentMethod === PAYMENT_METHODS.OPEN_BANKING
    )

    const accountIsRestricted = computed(
      () => authStore.userProfile.customer.accountRestricted || sendMoneyStore.accountIsRestricted
    )

    const isRiaGeneralStopMessage = ref(sendMoneyStore.riaGeneralStopMessage)

    const userCountry = authStore.userProfile.country

    const isMultiplePaymentMethodsAvailable = computed(() => {
      const paymentMethods = sendMoneyStore.form.paymentMethods
      const paymentMethodsAvailable = paymentMethods.filter((paymentMethod) => {
        return paymentMethod.isAvailable
      })
      return paymentMethodsAvailable.length > 1
    })

    const selectedPaymentMethod = computed(() => {
      return sendMoneyStore.form.paymentMethod
    })

    const goToHelpCentre = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.WHY_COULDN_T_MY_TRANSFER_BE_COMPLETED,
        article: HelpDesk[HelpDeskProperties.WHY_COULDN_T_MY_TRANSFER_BE_COMPLETED],
      })
    }

    const goToPayments = async () => {
      if (quickTransferStore.isCurrentTransactionQuickTransfer) {
        sendMoneyStore.resetTransferId()

        await router.replace('/quick-transfer')
      } else {
        if (
          selectedPaymentMethod.value === PAYMENT_METHODS.DEBIT_CARD ||
          selectedPaymentMethod.value === PAYMENT_METHODS.CREDIT_CARD
        ) {
          sendMoneyStore.resetTransferId()

          await analyticsStore.track({
            event: SEGMENT_EVENTS.CHANGE_PAYMENT_METHOD_TRIGGERED,
            traits: {
              paymentType: selectedPaymentMethod.value,
              newCard: cardAccount.getIsNewCard(),
              cardBrand: paymentMethod.type,
              cardBillingCountry: paymentMethod.billingCountry,
              authorisationType: cardAccount.cardAuthorisationType,
              paymentProvider: authStore.userProfile.customer.cardPaymentGateway,
              location: SEGMENT_LOCATIONS.SEND_MONEY,
            },
          })
        }
        sendMoneyStore.setActiveStep('/send-money/recipient')
        sendMoneyStore.goToNextStep()
      }
    }

    const attemptAnotherTransaction = () => {
      switch (sendMoneyStore.form.transferType) {
        case TRANSFER_TYPE.CONVERT_BALANCE:
          router.replace('/convert-balance')
          break
        case TRANSFER_TYPE.FUND_BALANCE:
          router.replace('/fund-balance')
          break
        case TRANSFER_TYPE.QUICK_TRANSFER:
          router.replace('/quick-transfer')
          break
        case TRANSFER_TYPE.SEND_MONEY_FLOW:
        default:
          router.replace('/send-money/summary')
          break
      }
    }

    const isCustomerCardSpecificError = ref(false)

    const goToPaymentsVolt = async () => {
      await analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_PAYMENT_METHOD_TRIGGERED,
        traits: {
          sendMethod: sendMoneyStore.form.paymentMethod,
          accountType: authStore.user.customer.accountType,
        },
      })
      sendMoneyStore.setActiveStep('/send-money/recipient')
      sendMoneyStore.goToNextStep()
    }

    const howLongRefunds = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.HOW_LONG_DO_REFUNDS_TAKE,
        article: HelpDesk[HelpDeskProperties.HOW_LONG_DO_REFUNDS_TAKE],
      })
    }

    const goToSummary = async () => {
      sendMoneyStore.resetTransferId()

      await analyticsStore.track({
        event: SEGMENT_EVENTS.TRY_AGAIN_TRIGGERED,
        traits: {
          sendMethod: sendMoneyStore.form.paymentMethod,
          accountType: authStore.user.customer.accountType,
        },
      })
      attemptAnotherTransaction()
    }

    const goToActivity = async () => {
      await router.replace('/activity')
    }

    const cancelTransaction = async () => {
      sendMoneyStore.clearCardData()
      if (
        selectedPaymentMethod.value === PAYMENT_METHODS.DEBIT_CARD ||
        selectedPaymentMethod.value === PAYMENT_METHODS.CREDIT_CARD
      ) {
        await analyticsStore.track({
          event: SEGMENT_EVENTS.TRANSACTION_CANCELLED,
          traits: {
            paymentType: selectedPaymentMethod.value,
            newCard: cardAccount.getIsNewCard(),
            cardBrand: paymentMethod.type,
            cardBillingCountry: paymentMethod.billingCountry,
            authorisationType: cardAccount.cardAuthorisationType,
            paymentProvider: authStore.userProfile.customer.cardPaymentGateway,
            location: SEGMENT_LOCATIONS.SEND_MONEY,
          },
        })
      }

      await router.goBackTo({ name: 'Activity' }, true)
    }

    const isPaymentMethodError = sendMoneyStore.isPaymentMethodError && !accountIsRestricted.value

    const isDeirectDebitError =
      isPaymentMethodError &&
      isMultiplePaymentMethodsAvailable.value &&
      !isQuoteExpired &&
      selectedPaymentMethod.value === PAYMENT_METHODS.DIRECT_DEBIT

    const isCardQuoteExpiredError =
      isPaymentMethodError &&
      isMultiplePaymentMethodsAvailable.value &&
      !accountIsRestricted.value &&
      (selectedPaymentMethod.value === PAYMENT_METHODS.DEBIT_CARD ||
        selectedPaymentMethod.value === PAYMENT_METHODS.CREDIT_CARD) &&
      isQuoteExpired

    const isCardError =
      isPaymentMethodError &&
      isMultiplePaymentMethodsAvailable &&
      !isQuoteExpired &&
      (selectedPaymentMethod.value === PAYMENT_METHODS.DEBIT_CARD ||
        selectedPaymentMethod.value === PAYMENT_METHODS.CREDIT_CARD)

    const isOpenBankingQuoteExpireError =
      !isRiaGeneralStopMessage.value &&
      !isPaymentMethodError &&
      !accountIsRestricted.value &&
      isQuoteExpired &&
      isOpenBanking.value

    const isOpenBankingPaymentError =
      !isRiaGeneralStopMessage.value &&
      !isPaymentMethodError &&
      !accountIsRestricted.value &&
      !isQuoteExpired &&
      isOpenBanking.value

    const isGenericRiaError =
      isRiaGeneralStopMessage.value && !isPaymentMethodError && !accountIsRestricted.value

    const isUnexpectedError =
      !isRiaGeneralStopMessage.value &&
      !isPaymentMethodError &&
      !accountIsRestricted.value &&
      !isQuoteExpired &&
      !isOpenBanking.value

    const isOneStepTransferError =
      sendMoneyStore.form.transferType === TRANSFER_TYPE.QUICK_TRANSFER ||
      sendMoneyStore.form.transferType === TRANSFER_TYPE.FUND_BALANCE ||
      sendMoneyStore.form.transferType === TRANSFER_TYPE.CONVERT_BALANCE

    onBeforeMount(async () => {
      sendMoneyStore.resetAdditionalFields()

      let transferError = sendMoneyStore.transfer.error?.error
      let transferCategory = sendMoneyStore.form.paymentMethodText

      if (sendMoneyStore.form.paymentMethod === PAYMENT_METHODS.OPEN_BANKING) {
        transferError = transferError ?? sendMoneyStore.voltCheckoutStatus
        transferCategory = 'Open Banking'
      }
      if (
        sendMoneyStore.form.paymentMethod === PAYMENT_METHODS.CREDIT_CARD ||
        sendMoneyStore.form.paymentMethod === PAYMENT_METHODS.DEBIT_CARD
      ) {
        const error = sendMoneyStore.transfer.error
        if (error) {
          isCustomerCardSpecificError.value = ['PSAPI_07', 'PSAPI_013', 'PSAPI_005'].includes(error)
        }
      }

      analyticsStore.track({
        event: SEGMENT_EVENTS.SOMETHING_WENT_WRONG,
        traits: {
          category: transferCategory,
          error: transferError,
          errorDescription: sendMoneyStore.transfer.error?.description,
          location: SEGMENT_LOCATIONS.SEND_MONEY,
          email: authStore.user.customer.email,
        },
      })
    })

    return {
      isOneStepTransferError,
      isOpenBankingPaymentError,
      isOpenBankingQuoteExpireError,
      isGenericRiaError,
      goToPayments,
      isUnexpectedError,
      userCountry,
      isPaymentMethodError,
      isMultiplePaymentMethodsAvailable,
      selectedPaymentMethod,
      attemptAnotherTransaction,
      goToHelpCentre,
      cancelTransaction,
      $t,
      accountIsRestricted,
      isRiaGeneralStopMessage,
      goToActivity,
      isQuoteExpired,
      isOpenBanking,
      howLongRefunds,
      goToSummary,
      goToPaymentsVolt,
      TRANSFER_TYPE,
      isCustomerCardSpecificError,
      PAYMENT_METHODS,
      isCardQuoteExpiredError,
      isDeirectDebitError,
      isCardError,
    }
  },
}
</script>

<style scoped>
.description {
  @apply pt-4 text-base text-secondary-text leading-6 font-normal;
}
</style>
