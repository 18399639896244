var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppCard',{attrs:{"sheet":""}},[(_vm.isMultiplePaymentMethodsAvailable)?[(
        _vm.selectedPaymentMethod === _vm.PAYMENT_METHODS.DEBIT_CARD ||
        _vm.selectedPaymentMethod === _vm.PAYMENT_METHODS.CREDIT_CARD
      )?_c('AppCardImageTitle',{attrs:{"src":require('@galileo/assets/images/illustrations/cross-mark.svg'),"title":_vm.$t('PageSendMoneyFailed.TitleCard').value,"alt":"Warning"}},[_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneyFailed.DescriptionCard').value)+" ")])]):(_vm.selectedPaymentMethod === _vm.PAYMENT_METHODS.DIRECT_DEBIT && _vm.userCountry === 'US')?_c('AppCardImageTitle',{attrs:{"src":require('@galileo/assets/images/illustrations/cross-mark.svg'),"title":_vm.$t('PageSendMoneyFailed.TitleACH').value,"alt":"Warning"}},[_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneyFailed.DescriptionACH').value)+" ")])]):(
        _vm.selectedPaymentMethod === _vm.PAYMENT_METHODS.DIRECT_DEBIT &&
        _vm.userCountry === 'CA' &&
        !_vm.accountIsRestricted
      )?_c('AppCardImageTitle',{attrs:{"src":require('@galileo/assets/images/illustrations/cross-mark.svg'),"title":_vm.$t('PageSendMoneyFailed.TitleEFT').value,"alt":"Warning"}},[_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneyFailed.DescriptionEFT').value)+" ")])]):_c('AppCardImageTitle',{attrs:{"src":require('@galileo/assets/images/illustrations/cross-mark.svg'),"title":_vm.$t('PageSendMoneyFailed.TitleSinglePaymentMethod').value,"alt":"Warning"}},[_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneyFailed.DescriptionSinglePaymentMethod').value)+" ")])]),_c('ButtonGroup',[_c('AppButton',{attrs:{"analytics-name":"send-failed-change-payment-method"},on:{"click":function($event){return _vm.$emit('goToPayments')}}},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneyFailed.ButtonChangePaymentMethod').value)+" ")]),_c('AppButton',{attrs:{"analytics-name":"send-failed-cancel-transaction","theme":"secondary"},on:{"click":function($event){return _vm.$emit('cancelTransaction')}}},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneyFailed.ButtonCancelTransaction').value)+" ")])],1)]:[_c('AppCardImageTitle',{attrs:{"src":require('@galileo/assets/images/illustrations/cross-mark.svg'),"title":_vm.$t('PageSendMoneyFailed.TitleSinglePaymentMethod').value,"alt":"Warning"}},[_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneyFailed.DescriptionSinglePaymentMethod').value)+" ")])]),_c('ButtonGroup',[_c('AppButton',{attrs:{"analytics-name":"send-failed-update-payment-method"},on:{"click":function($event){return _vm.$emit('goToPayments')}}},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneyFailed.ButtonUpdatePaymentMethod').value)+" ")]),_c('AppButton',{attrs:{"theme":"secondary","analytics-name":"send-failed-visit-help-centre"},on:{"click":function($event){return _vm.$emit('goToHelpCentre')}}},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneyFailed.VisitHelpCentre').value)+" ")])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }