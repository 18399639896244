<template>
  <AppCard sheet>
    <AppCardImageTitle
      :src="require('@galileo/assets/images/illustrations/cross-mark.svg')"
      :title="$t('PageSendMoneyFailed.TitleVoltFailedError').value"
      alt="Warning"
    >
      <div class="description">
        {{ $t('PageSendMoneyFailed.DescriptionVoltFailedError').value }}
      </div>
    </AppCardImageTitle>
    <ButtonGroup>
      <AppButton
        analytics-name="send-failed-change-payment-method"
        @click="$emit('goToPaymentsVolt')"
      >
        {{ $t('PageSendMoneyFailed.ButtonChangePaymentMethod').value }}
      </AppButton>
      <AppButton
        theme="secondary"
        analytics-name="send-failed-try-again-button"
        @click="$emit('goToSummary')"
      >
        {{ $t('PageSendMoneyFailed.ButtonTryAgain').value }}
      </AppButton>
    </ButtonGroup>
  </AppCard>
</template>

<script>
import { AppButton, AppCardImageTitle, AppCard } from '@oen.web.vue2/ui'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
import { useI18nStore } from '@galileo/stores'

export default {
  name: 'OpenBankingPaymentFailedCard',
  components: {
    ButtonGroup,
    AppCardImageTitle,
    AppButton,
    AppCard,
  },
  emits: ['goToPaymentsVolt', 'goToSummary'],
  setup() {
    const { $t } = useI18nStore()
    return { $t }
  },
}
</script>
